@tailwind base;
@tailwind components;
@tailwind utilities;
/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* Custom CSS Variables */
:root {
  /* Main Colors from New Palette */
  --color-oxford-blue: #0B132B;
  --color-space-cadet: #1C2541;
  --color-yinmn-blue: #3A506B;
  --color-verdigris: #5BC0BE;
  --color-fluorescent-cyan: #6FFFE9;
 
  /* Derived Background Colors */
  --color-bg: var(--color-oxford-blue);
  --color-bg-variant: var(--color-space-cadet);
 
  /* UI Element Colors */
  --color-primary: var(--color-verdigris);
  --color-primary-variant: var(--color-yinmn-blue);
  --color-secondary: var(--color-fluorescent-cyan);
  --color-accent: var(--color-verdigris);
 
  /* Text Colors */
  --color-white: #ffffff;
  --color-light: rgba(255, 255, 255, 0.8);
 
  /* Other Variables */
  --transition-base: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
}

/* Custom utility classes */
.text-gradient {
  background: linear-gradient(to right, var(--color-verdigris), var(--color-fluorescent-cyan));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.bg-gradient {
  background: linear-gradient(to right, var(--color-verdigris), var(--color-yinmn-blue));
}

.hover-gradient:hover {
  background: linear-gradient(to right, var(--color-verdigris), var(--color-fluorescent-cyan));
}

/* Base styles */
body {
  background-color: var(--color-bg);
  color: var(--color-white);
  font-family: 'Poppins', sans-serif;
  line-height: 1.7;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--color-bg-variant);
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, var(--color-verdigris), var(--color-yinmn-blue));
  border-radius: 4px;
}

/* Button styles */
.btn {
  background: var(--color-verdigris);
  color: var(--color-white);
  transition: var(--transition-base);
}

.btn:hover {
  background: var(--color-yinmn-blue);
  box-shadow: 0 0 15px var(--color-verdigris);
}

.btn-secondary {
  background: transparent;
  border: 2px solid var(--color-verdigris);
  color: var(--color-verdigris);
}

.btn-secondary:hover {
  background: var(--color-verdigris);
  color: var(--color-white);
}

/* Section styles */
section {
  padding: 6rem 0;
  position: relative;
}

/* Glass effect */
.glass {
  background: rgba(11, 19, 43, 0.05);
  backdrop-filter: blur(16px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}